export default {
  methods: {
    getDropdownFormattedObject (data, valueColumn, textColumn) {
      return new Promise((resolve) => {
        let options = []
        data.forEach(function (value, index) {
          options[index] = {
            value: value[valueColumn],
            text: value[textColumn]
          }
        })
        resolve(options)
      })
    },
    compare(value1, value2, operator) {
      switch (operator) {
        case '==':
          return value1 == value2;
        case '===':
          return value1 === value2;
        case '!=':
          return value1 != value2;
        case '!==':
          return value1 !== value2;
        case '<':
          return value1 < value2;
        case '<=':
          return value1 <= value2;
        case '>':
          return value1 > value2;
        case '>=':
          return value1 >= value2;
        default:
          throw new Error('Invalid operator');
      }
    }
  }
}

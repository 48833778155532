<template>
    <b-modal id="image-summary" size="lg" title="Image Summary" :no-enforce-focus="true" hide-footer @hidden="onHide">
        <component v-bind:is="dynamicComponent" @showImage="showImage" @showList="showList" :selectedProperty="selectedPropertyData"></component>
    </b-modal>
</template>

<script>
import ImageSummaryList from './ImageSummaryList'
import ImageSummaryView from './ImageSummaryView'

export default {
  name: 'ImageSummary.vue',
  data () {
    return {
      components: {
        list: ImageSummaryList,
        view: ImageSummaryView
      },
      selectedPropertyData: {},
      dynamicComponent: ''
    }
  },
  created () {
    this.dynamicComponent = this.components.list
  },
  methods: {
    showImage (propertyData) {
      this.selectedPropertyData = propertyData
      this.dynamicComponent = this.components.view
    },
    showList () {
      this.dynamicComponent = this.components.list
    },
    onHide () {
      this.dynamicComponent = this.components.list
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <div>
      <app-header></app-header>
      <div class="container-fluid toning-setting">
        <div class="row">
          <div class="col-md-9 col-sm-12 toning-setting__left">
            <app-preview></app-preview>
          </div>
          <div class="col-md-3 col-sm-12 toning-setting__right">
            <side-bar></side-bar>
          </div>
        </div>
      </div>
    </div>

    <prompt-popup></prompt-popup>
    <private-mode-popup></private-mode-popup>
    <b-modal modal-class="toning-modal" :no-enforce-focus="true" id="helpModal" title size="lg" hide-footer>
      <iframe width="100%" height="515" src="https://www.youtube.com/embed/d7ai4u8KrQs" frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </b-modal>
  </div>
</template>

<script>
import AppHeader from './AppHeader'
import SideBar from './SideBar'
import AppPreview from './AppPreview'
import PromptPopup from './includes/PromptPopup'
import PrivateModePopup from './includes/PrivateModePopup'
import TTInstanceService from '../services/TTInstanceService'
import NotificationService from '../services/NotificationService'
import TrainerService from '../services/TrainerService'
import { mapActions } from 'vuex'
import AuthService from '../services/AuthService'
import KeycloakService from '../services/KeycloakService'

export default {
  name: 'MainPage',
  components: { AppHeader, SideBar, AppPreview, PromptPopup, PrivateModePopup },
  data() {
    return {
      instanceService: new TTInstanceService(),
      notificationService: new NotificationService()
    }
  },
  computed: {
    component() {
      return this.$store.getters.getComponent
    },
    componentPropRefreshRequired() {
      return this.$store.getters.getComponentPropRefreshRequired
    },
    instance() {
      return this.$store.getters.getInstance
    }
  },
  watch: {
    component() {
      if (this.component) {
        this.syncComponentPropertiesByComponent(this.component)
      } else {
        this.$store.commit('components/setProperties', {})
      }
    },
    componentPropRefreshRequired() {
      if (this.componentPropRefreshRequired !== 0) {
        this.syncComponentPropertiesByComponent(this.componentPropRefreshRequired)
        this.$store.commit('setComponentPropRefreshRequired', 0)
      }
    }
  },
  async mounted() {

    if (KeycloakService.isLoggedIn() && this.$store.getters.getUser.id === undefined) {
      this.$store.commit('setUser', await (new AuthService()).setMe())
    }

    let trainerService = new TrainerService()
    await trainerService.getAll().then((response) => {
      this.$store.commit('trainers/set', response.data.trainers)

      let selectedTrainerId = this.$store.getters.getUser.trainer_id
      let themeActive = false
      let privateMode = false

      if (!selectedTrainerId) {
        let selectedTrainer = localStorage.getItem('selectedTrainer')
        if (selectedTrainer && selectedTrainer !== 'undefined') {
          selectedTrainerId = selectedTrainer
        }
      }

      let selectedTemplateId = 2
      let selectedTrainer = this.$store.getters['trainers/getById'](
        selectedTrainerId
      )

      if (selectedTrainer && selectedTrainer.active_template_id) {
        selectedTemplateId = selectedTrainer.active_template_id

        if (
          selectedTrainer.config_active &&
          selectedTrainer.config_active == 1
        ) {
          themeActive = true
        }

        if (selectedTrainer.private_mode && selectedTrainer.private_mode == 1) {
          privateMode = true
        }
      }

      this.$store.commit('setTemplate', { id: selectedTemplateId })
      this.$store.commit('setThemeActive', themeActive)
      this.$store.commit('setPrivateMode', privateMode)
      this.$store.commit('setTrainer', selectedTrainerId)

      if (selectedTrainerId) {
        this.$store.dispatch('loadInstance')
          .then(() => {
            Promise.all([
              this.loadPermissions(),
              this.loadAliases()
            ])
            this.syncComponents()
          })
      }
    })
  },
  methods: {
    ...mapActions({
      loadPermissions: 'permissions/load',
      loadAliases: 'aliases/load'
    }),
    syncComponents() {
      let instanceService = new TTInstanceService()
      instanceService.getComponents(this.instance.id).then((response) => {
        this.$store.commit('components/set', response.data.components)
      })
    },
    syncComponentPropertiesByComponent(componentId) {
      this.$store.commit('setLoading', true)
      this.$store.commit('components/setProperties', [])
      this.instanceService.getComponentData(this.$store.getters.getInstance.id, [componentId])
        .then(response => {
          this.$store.commit('components/setProperties', response.data.data)
          this.$store.commit('setLoading', false)
        })
        .catch(() => {
          this.notificationService.errorNotification('Something went wrong')
          this.$store.commit('setLoading', false)
        })
    }
  }
}
</script>

<style>
.toning-modal .modal-dialog {
  color: #fff;
}

.toning-modal .modal-content {
  background-color: #2c3434;
}

.toning-modal .modal-title {
  padding-left: 6px;

}

.toning-modal .modal-header {
  padding: 2px;
  border-bottom: none;
}

.toning-modal button {
  padding: 7px;
  color: #fff !important;
}
</style>
